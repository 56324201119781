(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/helper-get-products/assets/javascripts/sub-products-racing.js') >= 0) return;  svs.modules.push('/components/marketplace/helper-get-products/assets/javascripts/sub-products-racing.js');
"use strict";


const HIGH_FIVE = 14;

setGlobal('svs.components.marketplace.helpers.subProducts.racing', {
  id: {
    HIGH_FIVE
  },
  description: {
    [HIGH_FIVE]: 'High Five'
  }
});

 })(window);