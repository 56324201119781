(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/helper-get-products/assets/javascripts/get-products.js') >= 0) return;  svs.modules.push('/components/marketplace/helper-get-products/assets/javascripts/get-products.js');

'use strict';

const {
  subProducts
} = svs.components.marketplace.helpers;
const productBase = ['stryktipset', 'europatipset', 'fulltraff', 'topptipset', 'hastar', 'challenge', 'powerplay', 'bomben'];
const getProducts = function () {
  let {
    topptipsSeparate = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const products = productBase.slice();
  if (topptipsSeparate && products.find(product => product === 'topptipset')) {
    products.push('topptipset stryk', 'topptipset europa');
  }
  return products;
};
const getEnhancedProducts = () => {
  const racingName = 'hastar';
  const products = productBase.slice();
  const filteredProducts = products.filter(item => item !== racingName).map(product => ({
    product
  }));
  Object.values(subProducts.racing.id).sort((p1, p2) => p1 - p2).forEach(subProductId => {
    filteredProducts.push({
      product: racingName,
      subProductId
    });
  });
  return filteredProducts;
};

setGlobal('svs.components.marketplace.helpers.getEnhancedProducts', getEnhancedProducts);
setGlobal('svs.components.marketplace.helpers.getProducts', getProducts);

 })(window);